import React from "react";
import { Router } from "@reach/router";

import clientConfig from "../../client-config";
import Layout from "../components/layout";
import Project from "../components/project";
import HomePage from "../components/home-page";
import TeamPage from "../components/team-page";
import FaqPage from "../components/faq-page";
import CareersPage from "../components/careers-page";
import ArchivePage from "../components/archive-page";

const sanityClient = require("@sanity/client");

const client = sanityClient({
  ...clientConfig.sanity,
  useCdn: false, // `false` if you want to ensure fresh data
  withCredentials: true,
});

class PreviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
      type: null,
    };
  }
  componentDidMount() {
    const queryAllFields = `*[_id == "${this.props.document}"]  {
        ...,
      }`;
    const queryHomePage = `*[_id == "${this.props.document}"]  {
        ...,
        introLottieDesktop{
          asset->{
            url
          }
        },
        introLottieMobile{
          asset->{
            url
          }
        },
        introBackgroundVideo{
          asset->{
            url
          }
        },
        "selectedProjects" : caseStudiesHighlighted[]->
      }`;
    const queryArchive = `*[_id == "${this.props.document}"]  {
        "nodes" : projectList[]-> {
          ...
        },
        "cards" : projectList[] {
          ...,
          _type == 'lottieBrandCard' => {
            lottieFile{
              asset->{
                url
              }
            }
          }
        }
      }`;

    const queryTeamPage = `*[_id == "${this.props.document}"]  {
        ...,
        "members" : members[] {
          ...,
          _type == 'lottieBrandCard' => {
            lottieFile{
              asset->{
                url
              }
            }
          }
        }
      }`;

    const queryPageSections = `*[_id == "${this.props.document}"]  {
      ...,
        "content" : content[] {
          ...,
          _type == 'lottie' => {
            lottieFile{
              asset->{
                url
              }
            }
          },
          _type == 'twoColumn' => {
            "columns" : columns[] {
              ...,
              _type == 'lottie' => {
                lottieFile{
                  asset->{
                    url
                  }
                }
              }
            }
          }
        }
      }`;

    client
      .fetch(queryAllFields)
      .then((response) => {
        this.setState({
          type: response[0]._type,
        });
        switch (response[0]._type) {
          case "homePage":
            client.fetch(queryHomePage).then((res) => {
              this.setState({
                document: res[0],
              });
            });
            break;
          case "archiveSettings":
            client.fetch(queryArchive).then((res) => {
              const nodes = res[0].nodes;
              const cards = res[0].cards;

              const merged = nodes.map((node, index) => {
                const isCard = Object.keys(node).length == 0;
                if (isCard) {
                  return cards[index];
                } else {
                  return node;
                }
              });

              this.setState({
                document: merged,
              });
            });
            break;
          case "teamPage":
            client.fetch(queryTeamPage).then((res) => {
              this.setState({
                document: res[0],
              });
            });
            break;
          case "project":
          case "careersPage":
            client.fetch(queryPageSections).then((res) => {
              this.setState({
                document: res[0],
              });
            });
            break;
          default:
            client.fetch(queryAllFields).then((res) => {
              this.setState({
                document: res[0],
              });
            });
            return;
        }
      })
      .catch((error) => {
        console.log("problem", error);
      });
  }
  renderPreview() {
    if (this.state.document) {
      switch (this.state.type) {
        case "homePage":
          return <HomePage {...this.state.document} />;
        case "project":
          return <Project {...this.state.document} />;
        case "teamPage":
          return <TeamPage {...this.state.document} />;
        case "faqPage":
          return <FaqPage {...this.state.document} />;
        case "careersPage":
          return <CareersPage {...this.state.document} />;
        case "archiveSettings":
          return <ArchivePage title="Selected Work" nodes={this.state.document} />;
        default:
          return (
            <div style={{ textAlign: "center", color: "white", padding: "8rem 0" }}>
              Yikes! This page type has no preview available.
            </div>
          );
          break;
      }
    }
  }
  render() {
    return <Layout>{this.renderPreview()}</Layout>;
  }
}

const Preview = () => {
  return (
    <div>
      <Router>
        <PreviewPage path="/preview/:document" />
      </Router>
    </div>
  );
};

export default Preview;
